<template>
  <div>
    <c-card :title="`[${map.mapName}] 도면 정보`" class="cardClassDetailForm firefighting-card">
      <!-- <template slot="card-description">
        <q-badge color="red" text-color="white" label="도면이미지에 마우스 우클릭을 통해 소방설비를 추가하세요." />
      </template> -->
      <!-- <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="LBL0000209" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template> -->
      <template slot="card-detail">
        <div :style="`height: ${zoomer.height}px;`" class="zommer-container col-12">
          <panZoom v-if="mapExists" ref="panZoom" selector="#zoomerImage" :options="zoomer.options">
            <q-img
              id="zoomerImage"
              :src="map.mapSrc"
              :style="`width: ${(zoomer.height - 30) * map.ratio}px; height: ${zoomer.height - 30}px;`"
            >
              <!-- <q-menu
                touch-position
                context-menu
                @before-show="beforeShow"
              >
                <q-list dense style="min-width: 100px">
                  <q-item clickable v-close-popup @click="addFireFighting">
                    <q-item-section avatar class="firefighting-mark-add">
                      <q-icon name="add" color="red" />
                    </q-item-section>
                    <q-item-section>소화기 배치</q-item-section>
                  </q-item>
                </q-list>
              </q-menu> -->
              <template 
                v-for="(mark, idx) in map.marks">
                <c-map-marker
                  :key="idx"
                  class="mark-firefighting"
                  :blink="mark.curdateDiff >= 30"
                  :style="`transform: translate(${(mark.locationXcoordinate * zoomer.ratio) - 15}px, ${(mark.locationYcoordinate * zoomer.ratio) - 15}px);z-index: auto; user-select: auto; width: 30px; height: 30px;`"
                  :isImg="true"
                  :imgSrc="mark.imagePath ? mark.imagePath : require('@/assets/images/free-icon-fire-extinguisher-5524203.png')"
                >
                  <template v-slot:innerMark>
                    <q-menu 
                      :ref="'markedproxy' + mark.idx" 
                      :content-class="'firefighting-menu-layer'"
                      anchor="top right"
                      self="top left">
                      <q-card 
                        flat bordered 
                        :class="['firefighting-menu-card']">
                        <q-item class="firefighting-card-section firefighting-card-item">
                          <q-item-section>
                            <q-item-label>
                              <q-badge rounded color="light-blue" :label="mark.sopFireFightingTypeName" />
                            </q-item-label>
                          </q-item-section>
                          <!-- <q-item-section side>
                            <q-icon name="delete" color="red" class="cursor-pointer" @click.stop="deleteFireFightingMark(mark)" />
                          </q-item-section> -->
                        </q-item>
                        <q-card-section class="firefighting-card-section">
                          <q-icon 
                            name="chevron_right" 
                            class="text-indigo" 
                          />
                          <b>소방설비명</b> : {{mark.fireFightingName}}
                        </q-card-section>
                        <q-card-section class="firefighting-card-section">
                          <q-icon 
                            name="chevron_right" 
                            class="text-indigo" 
                          />
                          <b>설치번호</b> : {{mark.manageNo}}
                        </q-card-section>
                        <q-card-section class="firefighting-card-section">
                          <q-icon 
                            name="chevron_right" 
                            class="text-indigo" 
                          />
                          <b>최근 이슈점검일</b> : {{mark.checkDate}}
                        </q-card-section>
                      </q-card>
                    </q-menu>
                  </template>
                </c-map-marker>
              </template>
            </q-img>
          </panZoom>
        </div>
      </template>
    </c-card>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingUnitMap',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        sopFireFightingLocationId: null,
        sopFireFightingTypeCd: null,
        disposeFlag: 'N',
        loginDeptCd: '',
        sopFireFightingId: '',
      },
      zoomer: {
        height: 600,
        ratio: 1,
        style: 'width: 100%; height: 100%;',
        imageStyle: 'width: 90%; height: 90%;',
        value: 100,
        interval: 10,
        options: {
          minZoom: 0.5,
          maxZoom: 3,
          initialZoom: 1,
          initialX: 200,
          initialY: 200,
          bounds: true,
          boundsPadding: 0.1,
          zoomDoubleClickSpeed: 1, 
          onTouch: function(e) {
            return false;
          }
        },
        menu: {
          offsetX: 0,
          offsetY: 0,
        },
        currentMap: {
          sopFireFightingMapId: '',
          sopFireFightingLocationId: '',
        },
        maps: [],
      },
      openMarkInfo: {},
      editable: true,
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    map() {
      let map = {
        sopFireFightingMapId: '',
        mapSrc: '',
        mapName: '',
        ratio: 0,
        marks: []
      }
      if (this.mapExists) {
        map = this.$_.find(this.zoomer.maps, { sopFireFightingMapId: this.zoomer.currentMap.sopFireFightingMapId })
      }
      return map;
    },
    mapExists() {
      return this.zoomer.maps && this.zoomer.maps.length > 0 && Boolean(this.zoomer.currentMap.sopFireFightingMapId)
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFightingResultMap.list.url
      // this.detailUrl = selectConfig.fft.fireFightingResultMap.get.url
      // this.insertUrl = transactionConfig.fft.fireFightingResultMap.insert.url;
      // this.updateUrl = transactionConfig.fft.fireFightingResultMap.update.url;
      // this.deleteUrl = transactionConfig.fft.fireFightingResultMap.delete.url;
      // code setting
      this.$set(this.searchParam, 'sopFireFightingId', this.popupParam.sopFireFightingId)
      this.$set(this.searchParam, 'loginDeptCd', this.$store.getters.user.deptCd)
      // list setting
      this.getList();
      this.setSize();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.zoomer, 'maps', _result.data)
        this.$set(this.zoomer.currentMap, 'sopFireFightingLocationId', this.searchParam.sopFireFightingLocationId)
        let idx = this.$_.findIndex(this.zoomer.maps, { sopFireFightingMapId: this.zoomer.currentMap.sopFireFightingMapId });
        if (this.zoomer.maps && this.zoomer.maps.length > 0 
          && idx === -1) {
          this.$set(this.zoomer.currentMap, 'sopFireFightingMapId', this.zoomer.maps[0].sopFireFightingMapId)
        } else if (!this.zoomer.maps || this.zoomer.maps.length === 0) {
          this.$set(this.zoomer.currentMap, 'sopFireFightingMapId', '')
        }
        console.log(this.$_.clone(this.zoomer))
      },);
    },
    getDetail(sopFireFightingMapId) {
      if (!sopFireFightingMapId) return;
      this.$http.url = this.$format(this.detailUrl, sopFireFightingMapId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        /**
         * 특정 지도 정보의 위치정보만 refresh
         */
        if (this.zoomer.maps && this.zoomer.maps.length > 0
          && _result.data && _result.data.marks && _result.data.marks.length > 0) {
          let idx = this.$_.findIndex(this.zoomer.maps, { sopFireFightingMapId: sopFireFightingMapId })
          this.$set(this.zoomer.maps[idx], 'marks', _result.data.marks)
        }
      },);
    },
    setSize() {
      this.zoomer.height = window.innerHeight - 200;
      this.zoomer.ratio = (this.zoomer.height - 30) / 575;
    },
    setStyle(mark) {
      // 너비, 높이가 30px임으로 중간을 맞추기 위해 15px만금 조정
      let style = `transform: translate(${mark.locationXcoordinate - 15}px, ${mark.locationYcoordinate - 15}px);`
        + `z-index: auto; user-select: auto; width: 30px; height: 30px;`
      return style;
    },
    beforeShow(event) {
      let ratio = 575 / (this.zoomer.height - 30)
      this.$set(this.zoomer.menu, 'offsetX', event.offsetX * ratio)
      this.$set(this.zoomer.menu, 'offsetY', event.offsetY * ratio)
    },
    mapSelect(mapInfo) {
      this.$set(this.zoomer.currentMap, 'sopFireFightingMapId', mapInfo.sopFireFightingMapId)
    },
  }
};
</script>
<style lang="sass">
.custom-zoomer
  border: solid 1px silver
  background-color: white !important
.mark-firefighting
  background: unset !important
  cursor: pointer
    
.firefighting-menu-layer
  border-radius: 10px !important
  max-width: 600px !important
.firefighting-menu-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  .firefighting-card-section
    padding: 0 10px 0 10px
  .firefighting-card-item
    min-height: auto

.zommer-container
  .vue-pan-zoom-scene
    overflow: hidden

.firefighting-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important

.firefighting-map-list
  padding-right: 0 !important
  .q-item
    flex-direction: column
    align-items: center

.firefighting-mark-add
  min-width: 0 !important
  padding: 0 !important



.rainbow
  position: relative
  z-index: 0
  width: 400px
  height: 300px
  border-radius: 10px
  overflow: hidden
  padding: 2rem
  display: flex
  justify-content: center
  align-items: center
  font-family: sans-serif
  font-weight: bold
  &::before
    content: ''
    position: absolute
    z-index: -2
    left: -50%
    top: -50%
    width: 200%
    height: 200%
    background-color: #399953
    background-repeat: no-repeat
    background-size: 50% 50%, 50% 50%
    background-position: 0 0, 100% 0, 100% 100%, 0 100%
    background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5)
    animation: rotate 4s linear infinite
  &::after
    content: ''
    position: absolute
    z-index: -1
    left: 6px
    top: 6px
    width: calc(100% - 12px)
    height: calc(100% - 12px)
    background: white
    border-radius: 5px

</style>
<style scoped>

.circle {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 2.5px;
  background-clip: content-box;
  animation: spin 10s linear infinite;
}

.circle-wrapper:active .circle {
  animation: spin 2s linear infinite;
}

.success {
  background-color: #4BB543;
  border: 2.5px dashed #4BB543;
}

.error {
  background-color: #CA0B00;
  border: 2.5px dashed #CA0B00;
}

.warning {
  background-color: #F0D500;
  border: 2.5px dashed #F0D500;
}
@keyframes spin { 
  100% { 
    transform: rotateZ(360deg);
  }
}
</style>